export type YoutubeAvailableThumbnailSizes =
  | 'high'
  | 'standard'
  | 'default'
  | 'medium'

interface YoutubeItem {
  snippet?: {
    thumbnails: Record<YoutubeAvailableThumbnailSizes, { url: string }>
  }
  status?: {
    embeddable: boolean
  }
}

export interface YoutubeData {
  items: YoutubeItem[]
}

export interface BandcampData {
  track_id: string
  thumbnail: string
}

export interface SoundcloudData {
  id: string
  img: string
  track_slug: string
  access: 'blocked' | 'playable' | 'preview'
  monetization_model: 'SUB_HIGH_TIER' | 'AD_SUPPORTED' | 'BLACKBOX'
}

export interface AudiomackData {
  track_name: string
  artist_name: string
  thumbnail: string
}

export type PlayerType = 'yt' | 'sc' | 'bc' | 'audiomack'
export interface DistantApiResponse {
  embeddedLink: string
  type: PlayerType | 'sp' | 'error'
  errPlaceholder: string
  err: DistantApiError
  thumbnail: null | string
}

export enum DistantApiError {
  ERROR = -1,
  NO_ERROR,
  NO_DATA,
  NOT_EMBEDDABLE,
  EMBEDDABLE,
}
